<template>
  <div>
    <div></div>
    <div>
      <div class="font-24 mt-10 ml-4">Edit Appointment</div>
      <div class="justify-center" align="center">
        <!-- Top Bar Chart -->
        <v-card class="mt-2 mx-4">
          <div class="panel-content">
            <div class="row">
              <div class="col-md-12">
                <div class="widget">
                  <div class="form-elements-sec">
                    <div class="form-body">
                      <form
                        name="details"
                        class="formPart text-left"
                        @submit.prevent="submitForm(id)"
                      >
                        <!-- Customer Id -->

                        <div>
                          <div class="form-group col">
                            <label for="id">Id:</label>
                            <input
                              type="text"
                              class="form-control"
                              id="message"
                              placeholder="Enter Id"
                              v-model.trim="customerId"
                              @input="setTouch('_id')"
                            />
                            <div
                              class="validation"
                              v-if="!$v.customerId.required && $v.customerId.$error"
                            >
                              ID is required
                            </div>
                          </div>
                        </div>
                        <!---Name--->

                        <div>
                          <div class="form-group col">
                            <label for="name">Name:</label>
                            <input
                              type="text"
                              class="form-control"
                              id="name"
                              placeholder="Enter name"
                              v-model.trim="name"
                              @input="setTouch('name')"
                            />
                            <div class="validation" v-if="!$v.name.required && $v.name.$error">
                              Name is required
                            </div>
                          </div>
                        </div>

                        <!---Date---> 

                        <div>
                          <div class="form-group col">
                            <label for="date">Date:</label>
                            <input
                              type="date"
                              class="form-control"
                              id="date"
                              placeholder="Enter Date"
                              v-model.trim="details.date"
                              @input="setTouch('date')"
                            />
                            <div
                              class="validation"
                              v-if="!$v.details.date.required && $v.details.date.$error"
                            >
                              Date is required
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="form-group col">
                            <label for="name">Message:</label>
                            <input
                              type="text"
                              class="form-control"
                              id="message"
                              placeholder="Enter Mesaage"
                              v-model.trim="details.message"
                              @input="setTouch('message')"
                            />
                            <div
                              class="validation"
                              v-if="!$v.details.message.required && $v.details.message.$error"
                            >
                              Message is required
                            </div>
                          </div>
                        </div>

                        <!--Status -->
                        <div>
                          <div class="form-group col">
                            <label for="mobile">Status:</label>
                            <v-select
                              v-model="details.status"
                              :items="status"
                              label="Select Status"
                              solo
                              color="grey"
                              append-icon="▼"
                            ></v-select>
                            <div
                              class="error"
                              v-if="!$v.details.status.required && $v.details.status.$error"
                            >
                              status required
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="form-group col">
                            <label for="mobile">Slot Time:</label>
                            <v-select
                              v-model="details.slotsTime"
                              :items="timings"
                              label="Select Status"
                              solo
                              color="grey"
                            ></v-select>
                            <div
                              class="error"
                              v-if="!$v.details.slotsTime.required && $v.details.slotsTime.$error"
                            >
                              slot time required
                            </div>
                          </div>
                        </div>
                        <div></div>
                        <!-- button -->
                        <div class="btnPart text-center my-4">
                          <button type="submit" class="btn btn-success">Save</button>
                          <p class="typo__p" v-if="submitStatus === 'OK'">
                            Thanks for your submission!
                          </p>
                          <p class="typo__p" v-if="submitStatus === 'ERROR'">
                            Please fill the form correctly.
                          </p>
                          <button type="reset" class="btn btn-danger mx-3" v-on:click="resetForm()">
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import appointmentService from '../../services/appointmentService';

export default {
  components: {},
  data() {
    return {
      details: {
        message: '',
        slotsTime: '',
        date: '',
        status: '',
      },
      submitStatus: false,
      id: '',
      customerId: '',
      name: '',
      email: '',
      mobile: '',
      comments: '',
      status: ['open', 'Resolved'],
      timings: ['09.00 AM - 11.00 AM', '11.00AM - 1.00PM', '1.00PM - 3.00PM', '3.00PM -6.00PM'],
    };
  },
  validations: {
    details: {
      date: {
        required,
      },
      message: {
        required,
      },
      slotsTime: {
        required,
      },
      status: {
        required,
      },
    },
    customerId: {
      required,
    },
    name: {
      required,
    },
    comments: {
      required,
    },
  },
  created() {
    this.id = this.$route.params.id;
    if (this.id) {
      this.getOneAppointment(this.id);
    }
  },
  methods: {
    setTouch(fields) {
      if (fields === 'message') {
        this.$v.details.message.$touch();
      } else if (fields === 'status') {
        this.$v.details.status.$touch();
      } else if (fields === 'date') {
        this.$v.details.slotsTime.$touch();
      } else if (fields === 'date') {
        this.$v.details.date.$touch();
      } else if (fields === '_id') {
        this.$v.customerId.$touch();
      } else if (fields === 'name') {
        this.$v.name.$touch();
      } else if (fields === 'comments') {
        this.$v.comments.$touch();
      }
    },
    // this is use for save button
    async submitForm(id) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$store.commit('setSnackbar', {
          content: 'please fill form correctly',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });

        this.submitStatus = 'false';
      } else {
        console.log(id, 'id');
        this.submitStatus = 'true';
        const result = await appointmentService.updateAppointment(id, this.details);
        if (result.status === 200) {
          this.$store.commit('setSnackbar', {
            content: 'Submitted Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
          this.$router.push('/viewAppointments');
        }
        console.log(result);
      }
    },
    async getOneAppointment(id) {
      console.log(id, 'id');
      const result = await appointmentService.getOneAppointment(id);
      this.details.message = result.data.message;
      this.details.slotsTime = result.data.slotsTime;
      const dateObj = new Date(result.data.date);
      var month = dateObj.getUTCMonth() + 1; //months from 1-12
      if (month < 10) month = '0' + month;
      var day = dateObj.getUTCDate();
      if (day < 10) day = '0' + day;
      var year = dateObj.getUTCFullYear();
      const newDate = year + '-' + month + '-' + day;
      this.details.date = newDate;
      this.customerId = result.data.user._id;
      this.name = result.data.user.name;
      this.details.status = result.data.appointmentStatus;
      console.log(result, 'result');
    },

    resetForm() {
      this.details = 'blank';
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },

  // this method use for cancel button
};
</script>

<style lang="scss" scoped>
.validation {
  color: red;
}
</style>
